<template>
    <div class="wrapper">
        <div class="heading">
            <h1>{{ $t('Bypass Order') }}</h1>
        </div>
        <div class="flex">
            <form style="display: contents;" @submit.prevent>
                <div>
                    <p class="input-text">Order id</p>
                    <input id="id-input" class="input" type="text" v-model="orderId" autofocus>
                </div>
                <button class="button bypass" @click="BypassOrder">
                    Bypass
                </button>
            </form>
        </div>
        <div class="last-scanned-item-wrapper">
            <div class="s-heading">Last scanned</div>
            <p class="text"><b>Id:</b> {{ lastScannedItem.increment_id }}</p>
            <p class="text"><b>{{ $t('Shipping Method') }}:</b> {{ lastScannedItem.shipping_description }}</p>
            <div class="last-scanned-item-container" v-if="lastScannedItem.items.length > 0">
                <div class="last-scanned-item" v-for="item in lastScannedItem.items" :key="item.id">
                    <img class="last-scanned-item-img" width="50px" height="50px" :src="item.img" alt="">
                    <p class="last-scanned-item-text">{{ item.name }}</p>
                </div>
            </div>
        </div>
        <div class="loading" v-if="loading">
            <div class="loading-element">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</template>

<script>
import { BPA } from '@/helpers/BPA'
import { Tool } from '@/helpers/Tool'

    export default {
        mixins: [ BPA, Tool ],
        data() {
            return {
                inputField: null,
                user: '',
                statusHistoryMessage: '',
                orderId: '',
                recentOrderId: '',
                loading: false,
                lastScannedItem: {
                    increment_id: '',
                    shipping_description: '',
                    items: []
                },
                token: null,
            }
        },
        mounted() {     
            this.user = JSON.parse(BPA.storage.getItem('user'));
            this.statusHistoryMessage = `Packship: [${this.UserName()}] gave to Vitalii`

            this.inputField = document.getElementById('id-input')

            this.inputField.focus()
        },
        methods: {
            UserName(string = this.user.display_name) {
                if (string.length > 50) {
                    string = string.split(' ');
                    string = string[0] + ' ' + string.slice(-1)[0];
                }
                return Tool.TitleCase(string);
            },
            async GetBypassOrder() {
                return await new Promise((resolve) => {
                    BPA.api.GetPackingOrder(this.orderId).then((response) => {
                        return BPA.api.response({ response, return: 'json'})
                    }).then((response) => {
                        if(!response.ok || !response.result) {
                            this.loading = false
                            return ''
                        }

                        resolve(response.result)
                    })
                })
            },
            async UpdateStatusHistory(orderId) {
                const params = {
                    order_id: orderId,
                    request: {mgs: this.statusHistoryMessage}
                }

                let response = await BPA.api.UpdateOrderCmsStatusHistory(params);

                if(response.status != 204) {
                    this.$eventHub.$emit('ShowMessages', {
                        message: 'CMS status update failed',
                        type: 'error',
                        hide: 4000,
                    });
                }
                else {
                    this.$eventHub.$emit('ShowMessages', {
                        message: 'CMS status updated',
                        type: 'success',
                        hide: 4000,
                    });
                }
            },
            async BypassOrder() {
                this.loading = true
                const current = Symbol()

                this.token = current;

                if(this.orderId == '') {
                    this.$eventHub.$emit('ShowMessages', {
                        message: 'Order id missing',
                        type: 'error',
                        hide: 4000,
                    });
                    this.loading = false
                    return
                }

                let bypassOrder = await this.GetBypassOrder()

                if(bypassOrder == '') {
                    this.$eventHub.$emit('ShowMessages', {
                        message: 'Failed getting order',
                        type: 'error',
                        hide: 4000,
                    });
                    this.loading = false
                    return
                }

                if(this.orderId == this.recentOrderId) {
                    this.UpdateStatusHistory(bypassOrder.id)
                    
                    this.orderId = ''

                    this.inputField.focus()
                    
                    this.loading = false
                    return
                }

                let IdToBypass = []
                
                IdToBypass.push(bypassOrder.id)

                let response = await BPA.api.BypassUsedInPacking(IdToBypass);

                if (response.status == 204) {
                    this.$eventHub.$emit('ShowMessages', {
                        message: 'Bypass complete',
                        type: 'success',
                        hide: 4000,
                    });
                    this.recentOrderId = JSON.parse(JSON.stringify(this.orderId));

                    this.orderId = ''

                    this.inputField.focus()
                } else {
                    this.$eventHub.$emit('ShowMessages', {
                        message: 'Bypass failed',
                        type: 'error',
                        hide: 4000,
                    });
                }
                
                if(current != this.token){
                    this.loading = false;
                    return;
                }

                this.lastScannedItem = bypassOrder

                this.loading = false
            }
        }
    }
</script>

<style lang="scss" scoped>
@import 'src/assets/style/variables/icons.scss';
@import 'src/assets/style/variables/colors.scss';
@import 'src/assets/style/variables/fonts.scss';

    .wrapper {
        padding: 0 30px 30px;
    }
    .heading {
        color: #C2C8CE;
        margin-bottom: 20px;
    }
    .flex {
        display: flex;
    }
    .input-text {
        font-weight: 600;
        padding-bottom: 2px;
        margin-top: 5px;
    }
    .input {
        font-size: 1em;
        padding: 7px;
        color: #333;
        appearance: none;
        display: flex;
        background: none;
        border: 1px solid rgba(60, 60, 60, 0.26);
        border-radius: 4px;
        white-space: normal;
        outline: none;
        margin-right: 10px;
    }
    .button {
        width: 70%;
        flex-shrink: 0;
        background-color: $green;
        margin-top: 25px;
        &.bypass {
            width: fit-content;
            height: fit-content;
            display: flex;
            align-self: flex-end;
        }
    }
    .s-heading {
        margin-top: 20px;
        font-size: 2em;
        font-weight: 700;
    }
    .text {
        margin-block: 20px;
        font-size: 1.2rem;
    }
    .last-scanned-item-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }
    .last-scanned-item {
        display: flex;
        max-width: fit-content;
    }
    .last-scanned-item-img {
        margin: auto;
    }
    .last-scanned-item-text {
        margin-left: 0.5rem;
        align-self: center;
    }
</style>